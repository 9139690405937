<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
  >
    <v-card>
      <v-card-title class="subheading">
        Verification Sent
      </v-card-title>
      <v-card-text>
        Verify your identity with your mobile app to complete login.
        <v-progress-linear
          v-model="progress"
          height="40"
          color="accent"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseButton @clicked="closed()" color="accent" text="Cancel" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheLoginVerification',
  computed: {
    ...mapGetters('authentication', [
      'getPendingTimeoutRemaining',
      'getLogoutTime',
    ]),
    isOpen: {
      get() {
        return this.getPendingTimeoutRemaining && this.getPendingTimeoutRemaining > 0;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    progress() {
      return 100 - Math.round(this.getPendingTimeoutRemaining / this.getLogoutTime * 100);
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    ...mapActions('authentication', [
      'deleteVerification',
      'clearStore',
    ]),
    async closed() {
      await this.deleteVerification();
      this.clearStore();
    },
  },
};
</script>
